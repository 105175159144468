import React from 'react';
import * as S from '../styles';

export const Navbar: React.FC = () => {
  return (
    <S.Container>
      <S.Heading>me</S.Heading>
    </S.Container>
  );
};
