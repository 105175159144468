import React, { HTMLAttributes } from 'react';

type Props = HTMLAttributes<SVGElement>;

export const GithubIcon = ({ ...all }: Props) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...all}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3038 0C5.50949 0 0 5.50827 0 12.3038C0 17.7397 3.52542 22.3517 8.41409 23.9787C9.029 24.0925 9.25475 23.7117 9.25475 23.3868C9.25475 23.0936 9.24334 22.1242 9.23804 21.0961C5.81511 21.8403 5.09283 19.6444 5.09283 19.6444C4.53314 18.2224 3.72672 17.8441 3.72672 17.8441C2.61039 17.0804 3.81086 17.0961 3.81086 17.0961C5.04638 17.1829 5.69694 18.3642 5.69694 18.3642C6.79431 20.245 8.57526 19.7012 9.27737 19.3868C9.388 18.5918 9.70666 18.0488 10.0585 17.7416C7.32568 17.4307 4.45287 16.3755 4.45287 11.661C4.45287 10.3179 4.9335 9.22032 5.72057 8.35868C5.59282 8.04857 5.17168 6.79717 5.83997 5.10241C5.83997 5.10241 6.87316 4.77193 9.22419 6.3638C10.2056 6.09119 11.2582 5.95447 12.3038 5.94958C13.3494 5.95447 14.4028 6.09119 15.3861 6.3638C17.7345 4.77193 18.7662 5.10241 18.7662 5.10241C19.4359 6.79717 19.0148 8.04857 18.887 8.35868C19.6759 9.22032 20.1531 10.3179 20.1531 11.661C20.1531 16.3869 17.2748 17.4272 14.535 17.7318C14.9763 18.1136 15.3696 18.8624 15.3696 20.0103C15.3696 21.6566 15.3555 22.9815 15.3555 23.3868C15.3555 23.7144 15.5768 24.098 16.2004 23.9772C21.0867 22.3485 24.6076 17.7381 24.6076 12.3038C24.6076 5.50827 19.0989 0 12.3038 0Z'
      fill='white'
    />
  </svg>
);
